import { Link } from 'gatsby';
import React from 'react';
import {SocialIcon} from 'react-social-icons';

const IconSize = '34px';

const Handle = 'foodwaretogo';
const Email = `hello@${Handle}.com`;
const Fb = `https://facebook.com/${Handle}`;
const Twitter = `https://twitter.com/${Handle}`;
const Insta = `https://instagram.com/${Handle}`;
const Tiktok = `https://www.tiktok.com/@${Handle}`
const LinkedIn = `https://linkedin.com/company/foodware`;

const Footer = () => (
  <footer className=" pt-8 text-gray-800 bg-black">

    <div className="flex pb-8 px-2 md:px-12 lg:px-24 lg:flex-row flex-col md:flex-col items-center justify-between md:space-x-4 font-body font-bold">

      <div className="flex lg:order-first md:order-last order-last ">
        <div className="flex-1">
          <p className="text-white lg:text-sm  md:text-xs text-xs whitespace-no-wrap">© {new Date().getFullYear()} FoodWare™. All rights reserved.</p>
        </div>
      </div>

      <div className="flex flex-row space-x-6 whitespace-no-wrap">
          <Link to="/privacy" className="text-white hover:underline hover:text-white lg:text-sm  md:text-xs text-xs">Privacy Policy</Link>
          <Link to="/terms" className="text-white hover:underline hover:text-white lg:text-sm  md:text-xs text-xs">Terms of Use</Link>
          <Link to="/faq" className="text-white hover:underline hover:text-white lg:text-sm  md:text-xs text-xs">FAQ</Link>
      </div>

      <div className="flex flex-row md:space-x-0 items-center">
        <SocialIcon url={Insta} target="_blank" style={IconStyle} fgColor="#ffffff" bgColor="#000000"/>&nbsp;
        <SocialIcon url={Tiktok} target="_blank" style={IconStyle} fgColor="#ffffff" bgColor="#000000"/>&nbsp;
        <SocialIcon url={Twitter} target="_blank" style={IconStyle} fgColor="#ffffff" bgColor="#000000"/>&nbsp;
        {/*<SocialIcon url={Fb} target="_blank" style={IconStyle} fgColor="#ffffff" bgColor="#000000"/>&nbsp;*/}
        <SocialIcon url={LinkedIn} target="_blank" style={IconStyle} fgColor="#ffffff" bgColor="#000000"/>&nbsp;
        <SocialIcon url="mailto:hello@foodwaretogo.com" style={IconStyle} fgColor="#ffffff" bgColor="#000000"/>
        <p className="my-2 text-white lg:text-sm md:text-xs text-xs">hello@foodwaretogo.com</p>
      </div>

    </div>
  </footer>
);

const IconStyle = {
  width: IconSize,
  height: IconSize,
}

export default Footer;
