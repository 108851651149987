import React from 'react';
import SEO from './SEO';
import Navbar from '../nav/Navbar';
import Footer from './Footer';
import CookieBar from './CookieBar';

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <Navbar />
      <main className="bg-gray text-gray-900">{children}</main>
      <Footer />
      <CookieBar />
    </>
  );
};

export default Layout;
