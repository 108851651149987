import React from 'react';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`,
};

const Button = ({
  children,
  className = '',
  type = 'button',
  size,
  color = '#A8E8B5',
}) => {
  return (
    <button
      type={type}
      style={{backgroundColor: color}}
      className={`
        ${sizes[size] || sizes.default}
        ${className}
        bg-primary
        hover:bg-primary-darker
        rounded
        text-black
    `}
    >
      {children}
    </button>
  );
};

export default Button;
