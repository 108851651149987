import React from "react";

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="225"
    height="50"
    version="1.2"
    viewBox="0 0 337.5 75"
  >
    <defs>
      <path
        d="M17.188-26.406v5.281h-9.25v5.25h8.546v5.281H7.937V0H1.923v-26.406zm0 0"
        id="prefix__b"
      />
      <path
        d="M.734-13.219c0-3.812 1.301-7.039 3.907-9.687 2.601-2.645 5.738-3.969 9.406-3.969 3.687 0 6.82 1.32 9.406 3.953 2.582 2.637 3.875 5.871 3.875 9.703 0 3.867-1.273 7.117-3.812 9.75C20.984-.832 17.828.484 14.046.484 10.18.484 6.993-.82 4.485-3.437 1.984-6.051.734-9.314.734-13.22zm6.5 0c0 1.387.211 2.653.641 3.797.426 1.137 1.172 2.102 2.234 2.89 1.07.794 2.383 1.188 3.938 1.188 2.238 0 3.926-.765 5.062-2.297 1.145-1.539 1.72-3.398 1.72-5.578 0-2.156-.587-4.004-1.75-5.547-1.157-1.55-2.837-2.328-5.032-2.328-2.18 0-3.86.778-5.047 2.328-1.18 1.543-1.766 3.391-1.766 5.547zm0 0"
        id="prefix__c"
      />
      <path
        d="M1.922-26.406h7.64c3.676 0 6.813 1.258 9.407 3.765 2.601 2.512 3.906 5.653 3.906 9.422 0 3.805-1.297 6.961-3.89 9.469C16.397-1.25 13.257 0 9.562 0H1.921zM7.938-5.375h.921c2.196 0 3.993-.738 5.391-2.219 1.406-1.488 2.117-3.351 2.14-5.593 0-2.239-.703-4.102-2.109-5.594-1.398-1.5-3.203-2.25-5.422-2.25h-.921zm0 0"
        id="prefix__d"
      />
      <path
        d="M22.172 0l-4.219-14.813L13.734 0H7.047L-.484-26.406h6.687L10.72-8.281h.156l4.813-18.125h4.53l4.813 18.125h.157l4.515-18.125h6.656L28.83 0zm0 0"
        id="prefix__e"
      />
      <path
        d="M15.328-26.406L25.984 0h-6.468l-1.953-5.281H7.515L5.562 0H-.89L9.766-26.406zm.188 15.843l-2.938-7.671h-.062l-2.954 7.672zm0 0"
        id="prefix__f"
      />
      <path
        d="M1.922-26.406h9.437c2.54 0 4.61.68 6.204 2.031 1.601 1.355 2.406 3.293 2.406 5.813 0 1.949-.485 3.578-1.453 4.89-.97 1.305-2.29 2.227-3.953 2.766L23.64 0H16L7.937-10.438V0H1.923zm6.016 11.718h.703c.55 0 1.02-.007 1.406-.03a6.84 6.84 0 001.312-.22c.489-.132.88-.316 1.172-.546.301-.239.555-.582.766-1.032.219-.445.328-.988.328-1.625 0-.644-.11-1.191-.328-1.64-.211-.446-.465-.785-.766-1.016-.293-.238-.683-.422-1.172-.547a7.16 7.16 0 00-1.312-.218 28.25 28.25 0 00-1.406-.032h-.704zm0 0"
        id="prefix__g"
      />
      <path
        d="M17.031-26.406v5.281H7.938v5.25h8.703v5.281H7.938v5.313h9.093V0H1.921v-26.406zm0 0"
        id="prefix__h"
      />
      <clipPath id="prefix__a">
        <path d="M246.262 16.621h84v42h-84zm0 0" />
      </clipPath>
      <clipPath id="prefix__i">
        <path d="M255.68 21.328h74.25v37.5h-74.25zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#prefix__a)">
      <path
        fill="#F8D899"
        d="M330.223 16.621c0 23.2-18.801 42-41.996 42-23.196 0-41.922-18.8-41.922-42zm0 0"
      />
    </g>
    <use
      x={1.936}
      y={50.049}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xlinkHref="#prefix__b"
      xlinkType="simple"
      xlinkActuate="onLoad"
      xlinkShow="embed"
    />
    <use
      x={23.595}
      y={50.049}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xlinkHref="#prefix__c"
      xlinkType="simple"
      xlinkActuate="onLoad"
      xlinkShow="embed"
    />
    <use
      x={55.172}
      y={50.049}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xlinkHref="#prefix__c"
      xlinkType="simple"
      xlinkActuate="onLoad"
      xlinkShow="embed"
    />
    <use
      x={86.75}
      y={50.049}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xlinkHref="#prefix__d"
      xlinkType="simple"
      xlinkActuate="onLoad"
      xlinkShow="embed"
    />
    <use
      x={113.873}
      y={50.049}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xlinkHref="#prefix__e"
      xlinkType="simple"
      xlinkActuate="onLoad"
      xlinkShow="embed"
      fill="#A8E8B4"
    />
    <use
      x={153.256}
      y={50.049}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xlinkHref="#prefix__f"
      xlinkType="simple"
      xlinkActuate="onLoad"
      xlinkShow="embed"
      fill="#A8E8B4"
    />
    <use
      x={181.858}
      y={50.049}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xlinkHref="#prefix__g"
      xlinkType="simple"
      xlinkActuate="onLoad"
      xlinkShow="embed"
      fill="#A8E8B4"
    />
    <use
      x={208.285}
      y={50.049}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xlinkHref="#prefix__h"
      xlinkType="simple"
      xlinkActuate="onLoad"
      xlinkShow="embed"
      fill="#A8E8B4"
    />
    <g clipPath="url(#prefix__i)">
      <path
        fill="#A8E8B4"
        d="M329.898 21.328c0 20.715-16.62 37.5-37.125 37.5-20.507 0-37.062-16.785-37.062-37.5zm0 0"
      />
    </g>
  </svg>
);

export default Logo;
