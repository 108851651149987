import React from 'react';
import CookieConsent, {Cookies} from 'react-cookie-consent';

const CookieBar = () => {
  return (
    <CookieConsent
      debug={false} // NOTE: Disable for prod
      location="bottom"
      buttonText="Accept"
      // enableDeclineButton
      // declineButtonText="Decline"
      // flipButtons
      cookieName="gdpr-analytics-enabled"
      buttonStyle={ButtonStyle}
      style={ContainerStyle}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  )
}

const ContainerStyle = {
  backgroundColor:'white',
  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
  color: 'rgba(17, 24, 39)',
}

const PaddingHorizontal = 30;
const PaddingVertical = 10;

const ButtonStyle = {
  backgroundColor: '#A8E8B5',
  paddingLeft: PaddingHorizontal,
  paddingRight: PaddingHorizontal,
  paddingTop: PaddingVertical,
  paddingBottom: PaddingVertical,
  borderRadius: '0.25rem',
}

export default CookieBar;
