import React from 'react';
import styled from 'styled-components';
import Button from '../ui/Button';
import {Link} from 'gatsby';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 29px 10px;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #A8E8B5;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 200px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #000000;
    }
    div {
      right: 0;
    }
  }
`;

const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
      <li><Link className="px-4 text-black font-body" to="/">Home</Link></li>
      <li><Link className="px-4 text-black font-body" to="/mobile-app">Mobile App</Link></li>
      <li>
        <a className="px-4 text-black font-body" href="https://blog.foodwaretogo.com">
          Blog
        </a>
      </li>
      <li><Link className="px-4 text-black font-body" to="/faq">FAQ</Link></li>
      {/* <li><Link className="px-4 text-black " to="/about">About</Link></li>
      <li><Link className="px-4 text-black" to="/members">Members</Link></li>
      <div><Button className="mt-4 mb-4">Login</Button></div> */}
    </Ul>
  )
}

export default RightNav;
