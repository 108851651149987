import React from 'react';
import styled from 'styled-components';
import LogoIcon from '../../svg/Logo';
import {Link} from 'gatsby'
import Burger from './Burger';

const Nav = styled.nav`
  width: 100%;
  height: 80px;
  padding: 0 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    span {
      margin-left: -1rem;
    }
  }
`;

const Navbar = () => {
  return (
    <Nav className="bg-gray shadow">
      <div className="flex items-center text-2xl w-52 mr-3">
        <span><Link to="/" ><LogoIcon /></Link></span>
      </div>
      <Burger />
    </Nav>
  )
}

export default Navbar;